<template>
  <div id="documents">
    <h2>{{ $t('conference.documents.title') }}</h2>
    <ul class="documents__list">
      <li class="list-item" v-for="(document, index) in documents" :key="index">
        <v-icon class="list-item__icon">mdi-file-document-outline</v-icon>
        <a class="list-item_title" :href="document.url" target="_blank">{{ document.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Documents',
  props: ['documents'],
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.documents {
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    list-style: none;
    padding: 0;
  }
}

.list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  word-break: break-all;

  &__icon {
    font-size: 2rem;
    padding: 0 1rem;
  }
}
</style>
