<template>
  <v-sheet v-if="event && conference">
    <v-container class="survey__container" fluid>
      <v-sheet class="survey">
        <h2 class="conference-name">{{ conference.name }}</h2>
        <div class="first-line" v-if="hasUser">
          <video-switcher
            class="video"
            :user="loggedInUser"
            :video="conference"
            show-zoom-info
            disable-sound
          />

          <v-sheet dark class="chat__wrapper">
            <chat
              class="chat"
              v-if="hasChatRoom"
              :messages="messages"
              :user="loggedInUser"
              :canModerate="true"
              @load-more="loadMessages"
              @moderate-message="moderateMessage"
              @send-message="sendMessage"
            />
          </v-sheet>
        </div>

        <next-conference-btn
          v-if="canUpdateConferences"
          :conference="conference"
          :nextConferences="nextConferences"
          :now="now"
          :loading="conferenceIsLoading"
          @go-to-next-conference="goToNextConference"
        />

        <template v-if="!selectedQuestion">
          <v-sheet light class="question-wrapper">
            <div v-if="!question || questionHasCloseDate" class="question-wrapper__form">
              <div class="form__question-fields">
                <div class="question-fields__question">
                  <div class="question__label">{{ $t('survey.new-question.title') }}</div>

                  <v-textarea
                    v-model="questionForm.question"
                    :placeholder="$t('survey.new-question.placeholder')"
                    no-resize
                    rows="1"
                    class="question__input"
                  />
                </div>

                <div class="question-fields__type">
                  <v-select
                    outlined
                    dense
                    :hide-details="true"
                    color="primary"
                    :label="$t('survey.new-question.type')"
                    item-text="text"
                    item-value="value"
                    class="type__select"
                    :items="questionTypes"
                    v-model="questionForm.type"
                    @change="updateType"
                  />

                  <v-btn
                    class="type__button"
                    color="primary"
                    :loading="questionIsSaving"
                    :disabled="questionIsSaving || isMultipleWithNoAnswer || !canCreate"
                    @click="addQuestion()"
                  >
                    {{ $t('survey.actions.save') }}
                  </v-btn>
                </div>
              </div>

              <base-input-multiple
                class="form__answer"
                v-if="isMultiple(questionForm)"
                :label="$t('survey.new-answers.title')"
                :placeholder="$t('survey.new-answers.placeholder')"
                :values="questionForm.answers"
                @on-change="saveAnswers"
              />

              <v-btn
                class="form__button"
                color="primary"
                :loading="questionIsSaving"
                :disabled="questionIsSaving || isMultipleWithNoAnswer || !canCreate"
                @click="addQuestion()"
              >
                {{ $t('survey.actions.save') }}
              </v-btn>
            </div>

            <template v-else>
              <div class="question-wrapper__question-saved">
                <div class="question-saved__question">
                  <span class="question__text">{{ question.question }}</span>
                  <span class="question__started">
                    {{
                      $t('survey.created', {
                        date: $d(Date.parse(question.created), 'long'),
                      })
                    }}
                  </span>
                </div>

                <span class="question-saved__answers">
                  <template v-if="isMultiple(question)">
                    <v-btn
                      v-for="(answer, idx) in question.answers"
                      :key="idx"
                      class="result"
                      color="primary"
                      outlined
                    >
                      {{ answer }}
                    </v-btn>
                  </template>
                </span>

                <div class="question-saved__button-container">
                  <v-btn
                    class="mt-2"
                    color="primary"
                    :loading="questionIsSaving"
                    :disabled="questionIsSaving || !canUpdate"
                    @click="closeQuestion()"
                  >
                    {{ $t('survey.actions.close') }}
                  </v-btn>
                </div>
              </div>

              <div class="question-wrapper__pre-answers">
                {{
                  $tc('survey.history.number-answers', questionTotalAnswers, {
                    count: questionTotalAnswers,
                  })
                }}
              </div>
            </template>
          </v-sheet>

          <v-sheet class="history__title" dark>
            <h3>{{ $t('survey.questions.title') }}</h3>
          </v-sheet>

          <survey-questions
            class="history__content"
            v-if="waitingQuestionsSorted"
            :questions="waitingQuestionsSorted"
            :canDelete="canDelete"
            :canView="canView"
            :is-history="false"
            :no-data-text="$t('survey.questions.no-question')"
            :hasOngoingQuestion="hasOngoingQuestion"
            @start-question="startQuestion"
            @delete-question="deleteQuestion"
          />

          <v-sheet class="history__title" dark>
            <h3>{{ $t('survey.history.title') }}</h3>
          </v-sheet>

          <survey-questions
            class="history__content"
            v-if="historySorted"
            :questions="historySorted"
            :canDelete="canDelete"
            :canView="canView"
            :is-history="true"
            :no-data-text="$t('survey.history.no-question')"
            @select-question="selectQuestion"
            @delete-question="deleteQuestion"
          />
        </template>

        <template v-else>
          <div class="selected-question__header">
            <v-btn text color="primary" @click="backToList()">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t('globals.back') }}
            </v-btn>

            <div class="selected-question__info">
              <span>{{ selectedQuestion.question }}</span>

              <span class="line-info__date">
                {{
                  $t('survey.created', {
                    date: $d(Date.parse(selectedQuestion.created), 'time'),
                  })
                }}
              </span>

              <span class="line-info__date">
                {{
                  $t('survey.closed', {
                    date: $d(Date.parse(selectedQuestion.closedAt), 'time'),
                  })
                }}
              </span>
            </div>
          </div>
          <v-sheet v-if="answersSorted" class="answer__content" light>
            <div
              v-for="(answer, i) in answersSorted"
              :key="answer.id"
              v-bind:class="getColorClass(i)"
              class="answer__line"
            >
              <div class="line__user">
                <user-avatar class="hidden-xs-only" :avatar="getUserAvatar(answer)" :size="40" />
                <span class="user__name">{{ getUserName(answer) }}</span>
                <span class="user__answer">{{ answer.answer }}</span>
              </div>
            </div>
          </v-sheet>
        </template>
      </v-sheet>
    </v-container>
    <dialog-confirmation
      :visible="showConfirmation"
      :title="$t('survey.delete.deleteConfirmationDialog.title')"
      :content="$t('survey.delete.deleteConfirmationDialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { SurveyQuestionType } from '@/models';
import LayoutUtil from '@/helpers/layout/layout.helper';

import {
  ADD_SURVEY_QUESTION,
  CLEAR_SURVEY_ANSWERS,
  CLOSE_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
  GET_SURVEY_ANSWERS,
  GET_SURVEY_QUESTIONS,
  START_SURVEY_QUESTION,
  SURVEY_MODULE,
} from '@/stores/umanize-app/actions/survey/survey.actions';

import BaseInputMultiple from '@/components/base-input-multiple/BaseInputMultiple.vue';
import Chat from '@/components/chat/Chat.vue';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import VideoSwitcher from '@/components/video-switcher/VideoSwitcher.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import {
  APP_CONFERENCE_MODULE,
  CONFERENCE_CHAT_LOAD_MESSAGES,
  CONFERENCE_SEND_CHATS_MESSAGE,
  CONNECT_TO_CONFERENCE_SPEAKER_SOCKET,
  DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET,
  MODERATE_CONFERENCE_CHAT_MESSAGE,
} from '@/stores/umanize-app/actions/conference/app-conference.actions';
import {
  GET_CONFERENCE,
  GET_CONFERENCES_FOR_ROOM,
  GET_CONFERENCES_FOR_EVENT,
  GO_TO_NEXT_CONFERENCE_IN_ROOM,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { isSpeaker } from '@/models/ticketing/user-ticket';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import SurveyQuestions from '@/components/survey/survey-questions/SurveyQuestions.vue';
import NextConferenceBtn from './NextConferenceBtn.vue';

export default {
  name: 'Survey',
  components: {
    SurveyQuestions,
    BaseInputMultiple,
    Chat,
    UserAvatar,
    VideoSwitcher,
    NextConferenceBtn,
    DialogConfirmation,
  },
  data() {
    return {
      showConfirmation: false,
      questionForm: {
        question: '',
        type: SurveyQuestionType.text,
        answers: [],
      },
      questionTypes: [
        {
          value: SurveyQuestionType.text,
          text: this.$t('survey.type.text'),
        },
        {
          value: SurveyQuestionType.multiple_choice,
          text: this.$t('survey.type.multipleChoice'),
        },
      ],
      selectedQuestion: null,
      selectedQuestionToDelete: null,
      disableSubmitButton: false,
      timer: null,
      now: DateTime.local().toISO(),
    };
  },
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser', 'loggedInUserRoles']),
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_CONFERENCE_MODULE, [
      'conference',
      'conferenceIsLoading',
      'messages',
      'conferenceMessagesAreLoading',
      'nextConferences',
    ]),
    ...mapGetters(SURVEY_MODULE, [
      'question',
      'historySorted',
      'waitingQuestionsSorted',
      'questionIsSaving',
      'answersSorted',
      'questionTotalAnswers',
    ]),
    hasUser() {
      return !!this.loggedInUser;
    },
    isVimeoVideo() {
      return this.conference?.videoType === 'vimeo';
    },
    hasChatRoom() {
      return !!this.conference.hasChatRoom && this.conference.chatId;
    },
    questionHasCloseDate() {
      return !!this.question?.closedAt;
    },
    eventId() {
      return this.$route?.params?.eventId;
    },
    conferenceId() {
      return this.$route?.params?.conferenceId;
    },
    params() {
      return {
        eventId: this.eventId,
        conferenceId: this.conferenceId,
      };
    },
    isMultipleWithNoAnswer() {
      return this.isMultiple(this.questionForm) && !this.questionForm.answers.length;
    },
    canView() {
      return (
        isSpeaker(this.event?.ticket) ||
        PermissionsUtil.isAuthorized(
          ['permission.conferenceSurveys.canView'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canCreate() {
      return (
        isSpeaker(this.event?.ticket) ||
        PermissionsUtil.isAuthorized(
          ['permission.conferenceSurveys.canCreate'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canUpdate() {
      return (
        isSpeaker(this.event?.ticket) ||
        PermissionsUtil.isAuthorized(
          ['permission.conferenceSurveys.canUpdate'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canDelete() {
      return (
        isSpeaker(this.event?.ticket) ||
        PermissionsUtil.isAuthorized(
          ['permission.conferenceSurveys.canDelete'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canUpdateConferences() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    hasOngoingQuestion() {
      return !!this.question;
    },
  },
  methods: {
    ...mapActions(APP_CONFERENCE_MODULE, [
      GET_CONFERENCE,
      CONNECT_TO_CONFERENCE_SPEAKER_SOCKET,
      DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET,
      CONFERENCE_CHAT_LOAD_MESSAGES,
      CONFERENCE_SEND_CHATS_MESSAGE,
      MODERATE_CONFERENCE_CHAT_MESSAGE,
      GET_CONFERENCES_FOR_EVENT,
      GET_CONFERENCES_FOR_ROOM,
      GO_TO_NEXT_CONFERENCE_IN_ROOM,
    ]),
    ...mapActions(SURVEY_MODULE, [
      GET_SURVEY_QUESTIONS,
      ADD_SURVEY_QUESTION,
      CLOSE_SURVEY_QUESTION,
      START_SURVEY_QUESTION,
      GET_SURVEY_ANSWERS,
      CLEAR_SURVEY_ANSWERS,
      DELETE_SURVEY_QUESTION,
    ]),
    getColorClass(index) {
      return { line__gray: LayoutUtil.oneInTwo(index) };
    },
    getUserAvatar(answer) {
      return answer.user?.avatar;
    },
    getUserName(answer) {
      return `${answer.user?.firstName} ${answer.user?.lastName}`;
    },
    isMultiple(question) {
      return question.type === SurveyQuestionType.multiple_choice;
    },
    updateType(selectedType) {
      if (selectedType === SurveyQuestionType.text) {
        this.questionForm.answers = [];
      }
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
      this.selectedQuestionToDelete = null;
    },
    async confirmationDialogConfirm() {
      await this[DELETE_SURVEY_QUESTION]({
        ...this.$route.params,
        questionId: this.selectedQuestionToDelete.id,
      });
      await this.loadSurveyHistory();
      this.showConfirmation = false;
      this.selectedQuestionToDelete = null;
    },
    selectQuestion(question) {
      this.selectedQuestion = question;
      this[GET_SURVEY_ANSWERS]({
        ...this.params,
        questionId: question.id,
      });
    },
    deleteQuestion(question) {
      this.selectedQuestionToDelete = question;
      this.showConfirmation = true;
    },
    startQuestion(question) {
      this[START_SURVEY_QUESTION]({
        ...this.params,
        questionId: question.id,
      });
    },
    backToList() {
      this.selectedQuestionToDelete = null;
      this.selectedQuestion = null;
      this[CLEAR_SURVEY_ANSWERS]();
    },
    saveAnswers(answers) {
      this.questionForm.answers = answers;
    },
    sendMessage(message) {
      this[CONFERENCE_SEND_CHATS_MESSAGE]({
        eventId: this.conference.eventId,
        conferenceId: this.conference.id,
        chatId: this.conference.chatRoom,
        message,
      });
    },
    moderateMessage(message) {
      this[MODERATE_CONFERENCE_CHAT_MESSAGE]({
        eventId: this.conference.eventId,
        conferenceId: this.conference.id,
        message,
      });
    },
    async addQuestion() {
      const cleanedQuestion = {
        ...this.questionForm,
        question: this.questionForm.question.trim(),
      };

      await this[ADD_SURVEY_QUESTION]({
        ...this.params,
        question: cleanedQuestion,
      });

      this.questionForm = {
        question: '',
        type: SurveyQuestionType.text,
        answers: [],
      };
    },
    closeQuestion() {
      this[CLOSE_SURVEY_QUESTION]({
        ...this.params,
        questionId: this.question?.id,
      });
    },
    loadMessages() {
      if (!this.conferenceMessagesAreLoading) {
        this[CONFERENCE_CHAT_LOAD_MESSAGES](this.$route.params);
      }
    },
    goToNextConference() {
      this[GO_TO_NEXT_CONFERENCE_IN_ROOM]({
        eventId: this.conference.eventId,
        conferenceId: this.conference.id,
      });
    },
    async loadSurveyHistory() {
      await this[GET_SURVEY_QUESTIONS](this.params);
    },
    async initialize() {
      const { conferenceId } = this.params;
      await this.loadSurveyHistory();
      await this[GET_CONFERENCE](this.params);
      this[CONNECT_TO_CONFERENCE_SPEAKER_SOCKET]({ conferenceId, userId: this.loggedInUser.id });
      await this[GET_CONFERENCES_FOR_EVENT]({
        eventId: this.conference.eventId,
      });
      await this[GET_CONFERENCES_FOR_ROOM]({
        eventId: this.conference.eventId,
        roomId: this.conference.roomId,
      });
    },
  },

  async mounted() {
    await this.initialize();
  },
  created() {
    this.timer = window.setInterval(() => {
      this.now = DateTime.local().toISO();
    }, 60000);
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
  beforeDestroy() {
    const { conferenceId } = this.params;
    this[DISCONNECT_FROM_CONFERENCE_SPEAKER_SOCKET]({ conferenceId });
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.conference-name {
  margin-left: 1rem;
}

.survey {
  display: flex;
  flex-direction: column;

  &__container {
    min-height: calc(100vh - 6rem);
    padding: 0;
  }
}

.video {
  padding: 0 1rem;
  width: 100%;

  ::v-deep #zoom {
    width: 100%;
    height: 100%;
  }
}

.chat {
  width: 100%;
}

.next-conference {
  &__button {
    margin: 0 0 1rem 1rem;
  }
}

.question-wrapper {
  width: 100%;

  &__form {
    display: flex;
    flex-wrap: wrap;

    width: 90%;
    margin: auto;

    padding-top: 1rem;
  }

  &__question-saved {
    display: flex;
    flex-direction: column;

    padding: 0.5rem;
  }

  &__pre-answers {
    padding: 0 0.5rem;
  }
}

.form {
  &__question-fields {
    width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__answer {
    padding-top: 1rem;
    width: 100%;
  }

  &__button {
    margin-top: 1rem;
    width: 100%;
  }
}

.question-fields {
  &__question {
    width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__type {
    width: 100%;

    display: flex;
    flex-direction: column;
  }
}

.question-saved {
  &__question {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
  }

  &__answers {
    padding-bottom: 0.5rem;
  }
}

.question {
  &__label {
    width: 100%;

    font-weight: $semi-bold;
  }

  &__input {
    width: 100%;
  }

  &__started {
    font-size: $small-font-size;
  }
}

.answer,
.history {
  &__title {
    margin-top: 1rem;
    height: 3rem;
    width: 100%;
    padding: 0 2rem;

    display: flex;
    align-items: center;
  }
}

.selected-question {
  &__info {
    margin: 5px;
  }

  &__header {
    display: flex;
    align-items: flex-start;

    padding: 1rem 2rem;
  }
}

.selected-question {
  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__results {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.5rem 1rem;
    text-align: center;
  }

  &__links {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: space-evenly;
  }

  &__gray {
    background: #e1e2e2;
  }

  &__user {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.line-info {
  &__date {
    font-size: $small-font-size;
  }
}

.result {
  margin: 0.5rem;
  width: 12rem;
}

.user {
  &__name {
    font-weight: $semi-bold;
    font-size: $small-font-size;
    padding: 0 1rem 0 0;
  }

  &__answer {
    font-size: $small-font-size;
  }
}

.type {
  &__button {
    display: none;
  }
}

@include breakpoint(xsmall) {
  .form {
    &__button {
      width: auto;
    }
  }

  .answer,
  .history {
    &__line {
      padding: 0.5rem 2rem;
    }

    &__question {
      padding: 0.5rem 2rem 0;
    }
  }

  .question-wrapper {
    &__question-saved {
      flex-direction: row;
      padding: 0.5rem 2rem;
    }

    &__pre-answers {
      padding: 0 2rem;
    }
  }

  .question-saved {
    &__question {
      flex: 0 auto;
      padding-right: 0.5rem;
    }

    &__answers {
      flex: 1 0;
    }

    &__button-container {
      flex: 0 auto;
    }
  }
}

@include breakpoint(small) {
  .survey {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .first-line {
    display: flex;
    flex-direction: row;
    height: 20rem;
    width: 100%;
    align-items: center;
  }

  .video {
    width: 35.5rem !important;
    height: 20rem !important;
  }

  .chat {
    height: 20rem;

    &__wrapper {
      height: 20rem;
      width: calc(100% - 35.5rem);
    }
  }

  .question-fields {
    &__type {
      width: 100%;

      display: flex;
      flex-direction: row;
    }
  }

  .line {
    &__user {
      width: 90%;
      margin: 0 auto;
    }
  }

  .user {
    &__name {
      padding: 0 1rem;
    }
  }
}

@include breakpoint(medium) {
  .form {
    &__question-fields {
      width: 50%;
    }

    &__answer {
      width: 50%;
      padding: 1rem;
    }

    &__button {
      display: none;
    }
  }

  .type {
    &__select {
      width: 50%;
      padding-right: 0.5rem;
    }

    &__button {
      display: block;
      width: 50%;
      padding-left: 0.5rem;
    }
  }

  .line {
    &__results {
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
