<template>
  <div class="next-conference" v-if="hasNextConference">
    <v-btn
      class="next-conference__button"
      color="primary"
      :disabled="disableNextButton"
      @click="confirmGoToNextConference()"
      data-test-id="next-conference-btn"
    >
      {{ $t('conferenceRoom.next.title') }}
    </v-btn>

    <dialog-confirmation
      v-if="showConfirmation"
      :visible="showConfirmation"
      :title="$t('conferenceRoom.next.dialog.title')"
      :content="$t('conferenceRoom.next.dialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </div>
</template>

<script>
import DateUtil from '@/helpers/date/date.helper';
import DialogConfirmation from '../DialogConfirmation.vue';

export default {
  name: 'NextConferenceBtn',
  props: {
    conference: {
      type: Object,
      required: true,
    },
    now: {
      type: String,
      default: new Date().toISOString(),
    },
    nextConferences: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showConfirmation: false,
    disableNextButton: false,
  }),
  components: {
    DialogConfirmation,
  },
  computed: {
    hasNextConference() {
      return this.nextConferences.some(
        (conference) =>
          DateUtil.hasNextConference(this.conference.endTime, conference.startTime) &&
          conference.id !== this.conference.id,
      );
    },
  },
  methods: {
    confirmGoToNextConference() {
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
    },
    async confirmationDialogConfirm() {
      this.$emit('go-to-next-conference');
      this.showConfirmation = false;
      this.disableNextButton = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.next-conference {
  &__button {
    margin: 1rem 1rem 0 0;
  }
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
