<template>
  <v-sheet class="questions__content" light>
    <div class="questions__wrapper" v-if="!hasQuestions">{{ noDataText }}</div>
    <div
      v-for="(question, i) in questions"
      :key="question.id"
      v-bind:class="getColorClass(i)"
      class="questions__wrapper"
    >
      <div class="questions__question">
        {{ question.question }}
      </div>

      <div class="questions__line">
        <div class="line__info">
          <span v-if="question.startedAt" class="line-info__date">
            {{
              $t('survey.created', {
                date: $d(Date.parse(question.startedAt), 'time'),
              })
            }}
          </span>

          <span v-if="question.closedAt" class="line-info__date">
            {{
              $t('survey.closed', {
                date: $d(Date.parse(question.closedAt), 'time'),
              })
            }}
          </span>
        </div>

        <div class="line__results">
          <template v-for="(result, idx) in question.results">
            <template v-if="isMultiple(question)">
              <v-btn :key="idx" class="result" color="primary" outlined>
                {{ result.answer }}: {{ result.count || 0 }}
              </v-btn>
            </template>

            <span v-else :key="idx">
              {{ $tc('survey.history.number-answers', result.count, { count: result.count }) }}
            </span>
          </template>
        </div>

        <div class="line__links">
          <v-btn
            v-if="isHistory"
            text
            color="primary"
            :disabled="!canView"
            @click="selectQuestion(question)"
          >
            {{ $t('survey.history.see-results') }}
            <v-icon right>mdi-podium</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            color="primary"
            :disabled="!canView || hasOngoingQuestion"
            @click="startQuestion(question)"
          >
            {{ $t('survey.actions.start') }}
            <v-icon right>mdi-message-question</v-icon>
          </v-btn>
          <v-btn text color="primary" :disabled="!canDelete" @click="deleteQuestion(question)">
            {{ $t('globals.delete') }}
            <v-icon right>mdi-delete</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import LayoutUtil from '@/helpers/layout/layout.helper';
import { SurveyQuestionType } from '@/models';

export default {
  name: 'SurveyQuestions',
  props: ['questions', 'canDelete', 'canView', 'isHistory', 'noDataText', 'hasOngoingQuestion'],
  computed: {
    hasQuestions() {
      return this.questions?.length > 0;
    },
  },
  methods: {
    getColorClass(index) {
      return { line__gray: LayoutUtil.oneInTwo(index) };
    },
    selectQuestion(question) {
      this.$emit('select-question', question);
    },
    deleteQuestion(question) {
      this.$emit('delete-question', question);
    },
    startQuestion(question) {
      this.$emit('start-question', question);
    },
    isMultiple(question) {
      return question.type === SurveyQuestionType.multiple_choice;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.questions {
  &__wrapper {
    padding: 5px;
  }

  &__content {
    width: 100%;

    display: flex;
    flex-direction: column;
  }

  &__line {
    width: 100%;
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__question {
    width: 100%;
    padding: 0.5rem 0.5rem 0;
  }
}

.line {
  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: $small-font-size;
  }

  &__results {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.5rem 1rem;
    text-align: center;
  }

  &__links {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: space-evenly;
  }

  &__gray {
    background: #e1e2e2;
  }

  &__user {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
